import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import Img from "gatsby-image";
import Layout from "../components/layout/layout";
import Metadata from "../components/metadata/metadata";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const About = () => {
  const data = useStaticQuery(
    graphql`
      query {
        file(relativePath: { eq: "images/stripes/Pink-Filter-Offices.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 960, maxHeight: 150) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  );

  return (
    <Layout>
      <Metadata
        title="Terms of Use"
        description="Hope Labs terms of use clarify how users should interact with the content on this Site."
      />
      <section role="main">
        <Row className={"no-margin"}>
          <Img
            fluid={data.file.childImageSharp.fluid}
            alt="Office building by night with filters"
          />
        </Row>
        <Row className={"presentation"}>
          <Col className={"mb-2em"}>
            <h1 className={"mainTitle mt-2em"}>Terms of use</h1>
            <p className={"pureText"}>
              Hope Labs and its affiliates (“Hope Labs” or “we”) provides its
              content on its websites or applications that post a link to this
              Terms of Use (the “Site”) subject to the following terms and
              conditions (the “Terms”). We may periodically change the Terms
              without prior notice, so please check back from time to time.
              These Terms were last updated on April 5, 2021. By accessing and
              using this Site, you agree to these Terms. For an explanation of
              Hope Labs’ practices and policies related to the collection, use,
              and storage of our users’ information, please read our{" "}
              <Link to={"/privacy/"}>Privacy Policy</Link>.
            </p>

            <h4 className={"minorTitle mt-2em"}>I. Copyrights</h4>
            <p className={"pureText"}>
              All content and functionality on the Site, including text,
              graphics, logos, icons, images, and videos and the selection and
              arrangement thereof, in addition to any concepts, know-how, tools,
              frameworks, software, applications or other technology,
              algorithms, models, processes, and industry perspectives
              underlying or embedded in the foregoing, along with any
              enhancements to or derivative works thereof (the “Site Content”)
              is the exclusive property of Hope Labs or its licensors and, to
              the extent applicable, is protected by UK and international
              copyright laws. Neither the Site Content nor functionality of the
              Site, may be copied, reproduced, modified, reverse engineered,
              altered (including the removal or disabling of any security or
              technological safeguards, disclaimers, or legends) uploaded,
              published, uploaded, posted, transmitted, or distributed in any
              way without our written permission, except for those uses
              specified in Section III – Use of site content. All rights not
              expressly granted are reserved.
            </p>

            <h4 className={"minorTitle mt-2em"}>II. Trademarks</h4>
            <p className={"pureText"}>
              The trademarks, service marks, designs, and logos (collectively,
              the “Trademarks”) displayed on the Site are the registered and
              unregistered Trademarks of Hope Labs and its licensors. You agree
              that, except as expressly permitted by us (e.g., through social
              media sharing tools provided on the Site) or by our licensors,
              where applicable, you will not refer to or attribute any
              information to Hope Labs or its licensors in any public medium
              (e.g., press release, websites, or public social media) for
              advertising or promotion purposes, or for the purpose of informing
              or influencing any third party and that you will not use or
              reproduce any Trademark of, or imply any endorsement by or
              relationship with, Hope Labs or its licensors.
            </p>

            <h4 className={"minorTitle mt-2em"}>III. Use of site content</h4>
            <p className={"pureText"}>
              Hope Labs hereby grants you a limited, non-exclusive,
              non-transferable, revocable license for the term hereof to access
              and download, display, and print one copy of the Site Content on
              any single computer solely for your internal, business use,
              provided that you do not modify the Site Content in any way
              (including creating derivative works thereof), that you retain all
              copyright and other proprietary notices displayed on the Site
              Content, and that you otherwise comply with these Terms. You may
              not otherwise reproduce, modify, reverse engineer, distribute,
              transmit, post, or disclose the Site Content without Hope Labs'
              prior written consent. In addition, you may not “mirror” the Site
              Content or any portion thereof without Hope Labs' express written
              consent. Nothing on this Site should be construed as granting
              directly or indirectly, or by implication any license or right to
              use any Hope Labs intellectual property other than as expressly
              set forth herein. The license granted in this section terminates
              automatically and immediately if you do not comply with these
              Terms.
            </p>

            <h4 className={"minorTitle mt-2em"}>IV. User postings</h4>
            <p className={"pureText"}>
              You acknowledge and agree that Hope Labs shall own and have the
              unrestricted right to use, publish, and otherwise exploit any and
              all information that you post or otherwise publish on the Site in
              postings, forums or message boards, questionnaire, survey
              responses, and otherwise, and you acknowledge and agree that, by
              providing us any such submission, you automatically grant, and
              hereby do grant, to us a worldwide, non-exclusive, transferable,
              assignable, sublicensable, fully paid-up, royalty-free, perpetual,
              irrevocable license and right to use, reproduce, publish,
              distribute, modify and otherwise exploit such submission for any
              purpose, and in any form or media, not prohibited by applicable
              law. In addition, you hereby waive any claims against Hope Labs
              for any alleged or actual infringements of any rights of privacy
              or publicity, intellectual property rights, moral rights, or
              rights of attribution in connection with Hope Labs’ use and
              publication of such submissions.
            </p>
            <p className={"pureText"}>
              You covenant that you shall not post or otherwise publish on the
              Site any materials that (a) are threatening, libelous, defamatory,
              or obscene; (b) would constitute, or that encourage conduct that
              would constitute, a criminal offense, give rise to civil
              liability, or otherwise violate law; (c) infringe the intellectual
              property, privacy, or other rights of any third parties; (d)
              contain a computer virus or other destructive element; (e) contain
              advertising; (f) constitute or contain false or misleading
              statements; or (g) violates these Terms.
            </p>
            <p className={"pureText"}>
              Hope Labs does not represent or endorse the accuracy of
              reliability of information posted to the Site by users. In
              addition, Hope Labs does not and cannot review all information
              posted to the Site by users and is not responsible for such
              information. However, Hope Labs reserves the right to refuse to
              post and the right to remove any information, in whole or in part,
              for any reason or for no reason.
            </p>

            <h4 className={"minorTitle mt-2em"}>
              V. Notices of infringement and takedown by Hope Labs
            </h4>
            <p className={"pureText"}>
              Hope Labs prohibits the posting of any information that infringes
              or violates the copyright rights and/or other intellectual
              property rights (including rights of privacy and publicity) of any
              person or entity. If you believe that your intellectual property
              right (or such a right that you are responsible for enforcing) is
              infringed by any content on the Site, please write to Hope Labs at
              the address shown below, giving a written statement that contains:
              (a) identification of the copyrighted work and/or intellectual
              property right claimed to have been infringed; (b) identification
              of the allegedly infringing material on the Site that is requested
              to be removed; (c) your name, address, and daytime telephone
              number, and an e-mail address if available; (d) a statement that
              you have a good faith belief that the use of the copyrighted work
              and/or exercise of the intellectual property right is not
              authorized by the owner, its agent, or the law; (e) a statement
              that the information in the notification is accurate, and, under
              penalty of perjury, that the signatory is authorized to act on
              behalf of the owner of the right that is allegedly infringed; and
              (f) the signature of the intellectual property right owner or
              someone authorized on the owner’s behalf to assert infringement of
              the right. Hope Labs will remove any posted submission that
              infringes the copyright or other intellectual property right of
              any person upon receipt of such a statement (or any statement in
              conformance with the UK law). Under appropriate circumstances,
              persons who repeatedly submit infringing or unlawful material will
              be prohibited from posting further submissions. Hope Labs' contact
              for submission of notices under this Section V is:{" "}
              <a href="mailto:hopelabs@quasiscience.com">
                hopelabs@quasiscience.com
              </a>
              .
            </p>

            <h4 className={"minorTitle mt-2em"}> VI. Disclaimers</h4>
            <p className={"pureText"}>
              The content and functionality on the Site is provided with the
              understanding that Hope Labs is not herein engaged in rendering
              professional advice or services to you, no Site content is
              intended to serve as or shall be deemed investment, legal, tax,
              accounting or other regulated advice, and that you shall remain
              solely responsible for your use of all site content and
              acknowledge that any reliance upon the site content shall be
              entirely at your sole option and risk. All content and
              functionality on the site is provided “as is,” without warranty of
              any kind, either express or implied, including, without
              limitation, implied warranties of merchantability and fitness for
              a particular purpose. Hope Labs and its third-party content
              providers make no warranties, express or implied, as to the
              ownership, accuracy, or adequacy of the site content. Hope Labs
              shall have no liability or responsibility for any information
              published on linked websites, contained in any user submissions
              published on the site, or provided by third parties. Neither Hope
              Labs nor its third-party content providers shall be liable for any
              indirect, incidental, consequential, or punitive damages or losses
              or for lost revenues or profits, whether or not advised of the
              possibility of such damages or losses and regardless of the theory
              of liability.
            </p>

            <h4 className={"minorTitle mt-2em"}>VII. Indemnification</h4>
            <p className={"pureText"}>
              You hereby indemnify, defend, and hold harmless Hope Labs and all
              of its predecessors, successors, parents, subsidiaries,
              affiliates, officers, directors, shareholders, investors,
              employees, agents, representatives, and attorneys and their
              respective heirs, successors, and assigns (Hope Labs Indemnified
              Parties”) from and against any and all liability, expenses, costs,
              or other losses (“Losses”) incurred by Hope Labs and/or Hope Labs
              Indemnified Parties in connection to any claims arising out of
              your use of the Site and/or any breach by you of these Terms,
              including the representations, warranties and covenants you made,
              if any, by agreeing to these Term. Hope Labs reserves the right to
              assume, at its own expense, the exclusive defense and control of
              any matter otherwise subject to indemnification by you.
            </p>

            <h4 className={"minorTitle mt-2em"}>
              VIII. Third-party websites and Providers
            </h4>
            <p className={"pureText"}>
              We may provide links to third-party websites, and some of the
              content appearing to be on this Site is in fact supplied,
              supported, or provided directly or indirectly by third parties,
              for example, in instances of framing of third-party websites or
              incorporation through framesets of content supplied by third-party
              servers. Hope Labs has no responsibility for these third-party
              websites, which are governed by the terms of use and privacy
              policies, if any, of the applicable third-party content providers.
            </p>

            <h4 className={"minorTitle mt-2em"}>IX. Governing law</h4>
            <p className={"pureText"}>
              Hope Labs is a social enterprise registered in the UK.
            </p>

            <h4 className={"minorTitle mt-2em"}>Contact us</h4>
            <p className={"pureText"}>
              If you have any other questions about our Terms of Use, please
              contact us at:
            </p>
            <p className={"pureText"}>
              <span className={"minorTitle"}>e-mail:</span>{" "}
              <a href="mailto:hopelabs@quasiscience.com">
                hopelabs@quasiscience.com
              </a>
            </p>
            {/* <p >post:</p>
                            <p>To be decided,Buckingham,MK18 XXX,UK</p> */}
          </Col>
        </Row>
      </section>
    </Layout>
  );
};

export default About;
